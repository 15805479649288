import React from 'react'
import styles from './MarkdownToHTML.module.scss'

export default function MarkdownToHTML({ content, className, ...props }) {
  let markdownWrapperClass = null
  switch (className) {
    case 'artist':
      markdownWrapperClass = styles.artist
      break

    default:
      break
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content
      }}
      className={markdownWrapperClass}
      {...props}
    />
  )
}
