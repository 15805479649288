import React from 'react'
import Img from 'gatsby-image'
import styles from './HeroImage.module.scss'

export default function HeroImage({ image, alt }) {
  return (
    <div className={styles.imageWrapper}>
      <Img fluid={image} alt={alt}></Img>
    </div>
  )
}
