import React from 'react'
import { graphql } from 'gatsby'
import HeroImage from '../components/HeroImage/HeroImage'

import MarkdownToHTML from '../components/MarkdownToHTML/MarkdownToHTML'
import SEO from '../components/SEO/SEO'

export default function ArtistPage({ data }) {
  const {
    markdownRemark: {
      html,
      frontmatter: {
        image: {
          childImageSharp: { fluid }
        },
        alt
      }
    }
  } = data
  return (
    <>
      <SEO title="Artist" route="/artist"></SEO>
      <HeroImage image={fluid} alt={alt} />
      <MarkdownToHTML content={html} className="artist" />
    </>
  )
}

export const artistPageQuery = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/content/pages/artist.md/" }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }
    }
  }
`
